import React from "react"
import { useTranslation } from "react-i18next"

import * as UIKit from "uiKit"

const ErrorBoundaryFallback = (): JSX.Element => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col h-full items-center px-2 space-y-4 bg-slate-900">
      <div className="mt-4">
        <UIKit.ErrorBanner message={t("error_boundary.message")} />
      </div>
      <UIKit.RefreshButton />
    </div>
  )
}

export default ErrorBoundaryFallback
