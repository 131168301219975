type SendMessage = "REFRESH"

export const REFRESH_POST_MESSAGE: SendMessage = "REFRESH"

export const TOKEN_REFRESH = "TOKEN_REFRESH"

export const sendMessage = (message: SendMessage): void => {
  try {
    window.ReactNativeWebView.postMessage(message)
  } catch {
    console.log("Failed to send message for the mobile app")
  }
}

export const log = (message: string): void => {
  try {
    window.ReactNativeWebView.postMessage(message)
  } catch {
    console.log("Failed to log message for the mobile app")
  }
}
