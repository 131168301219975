import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useCallContext } from "App/CallProvider"
import cn from "classnames"
import * as LiveSwitchApp from "liveswitch"

import { Icons } from "assets"

const isLiveAgentAvailable = true

type View = "Vertical" | "Horizontal"

const CallConnectionWrapper = (): JSX.Element => {
  const [view, setView] = useState<View>("Vertical")

  const handleOnSwitchViewClick = (): void => {
    const nextView = view === "Vertical" ? "Horizontal" : "Vertical"
    setView(nextView)
  }

  const isVerticalView = view === "Vertical"
  const isHorizontalView = view === "Horizontal"

  return (
    <div className="flex flex-col justify-between items-center">
      <div
        className={cn(
          "relative flex gap-4 justify-center items-center transition-all duration-300",
          {
            "flex-row pt-4": isHorizontalView,
            "flex-col pb-4": isVerticalView,
          },
        )}
      >
        <CallConnection view={view} />
      </div>

      <div
        onTouchEnd={handleOnSwitchViewClick}
        onClick={handleOnSwitchViewClick}
        className="cursor-pointer opacity-80 p-4"
      >
        {isVerticalView ? <Icons.ArrowUp /> : <Icons.ArrowDown />}
      </div>
    </div>
  )
}

type CallConnectionProps = {
  view: View
}
const CallConnection = ({ view }: CallConnectionProps): JSX.Element => {
  const { state } = useCallContext()

  const isPendingCall = state.kind === "PendingCall"

  return (
    <>
      {isPendingCall && <PendingCall view={view} />}

      <VideoContainer view={view} />

      {!isLiveAgentAvailable && <UnavailableAgentPlaceholder />}

      <RingControls view={view} />
    </>
  )
}

type RingControlsProps = {
  view: View
}
const RingControls = ({ view }: RingControlsProps): JSX.Element => {
  const { changeState, state, ringtone } = useCallContext()

  const isAudioMuted = state.kind === "ActiveCall" && state.audioMuted
  const isVideoMuted = state.kind === "ActiveCall" && state.videoMuted

  const isPendingCall = state.kind === "PendingCall"

  const isAudioButtonDisabled = isPendingCall
  const isVideoButtonDisabled = isPendingCall

  const handleOnCallEndClick = (): void => {
    LiveSwitchApp.stopCall()
    ringtone?.pause()
    changeState({ kind: "ConnectionEstablished" })
  }

  const handleOnMicrophoneClick = (): void => {
    LiveSwitchApp.toggleMuteAudio()
    changeState({
      kind: "ActiveCall",
      audioMuted: !isAudioMuted,
      videoMuted: isVideoMuted,
    })
  }

  const handleOnVideoClick = (): void => {
    LiveSwitchApp.toggleMuteVideo()
    changeState({
      kind: "ActiveCall",
      audioMuted: isAudioMuted,
      videoMuted: !isVideoMuted,
    })
  }

  const isVerticalView = view === "Vertical"
  const isHorizontalView = view === "Horizontal"

  const buttonStyle =
    "w-6 h-6 p-3 rounded-full cursor-pointer items-center justify-center flex"
  const disabledButtonStyle = "pointer-events-none opacity-50 bg-gray-green-400"
  const containerStyle = cn("flex flex-row items-center justify-center", {
    "space-x-4": isHorizontalView,
    "space-x-8": isVerticalView,
  })

  const videoIconClassName = isVideoButtonDisabled
    ? "fill-gray-green-600"
    : "fill-slate-900"

  const audioIconClassName = isAudioButtonDisabled
    ? "fill-gray-green-600"
    : "fill-slate-900"

  return (
    <div className={containerStyle}>
      <div
        onClick={handleOnVideoClick}
        className={cn(buttonStyle, {
          "bg-emerald-300": !isVideoMuted,
          "bg-emerald-400": isVideoMuted,
          [disabledButtonStyle]: isVideoButtonDisabled,
        })}
      >
        {isVideoMuted ? (
          <Icons.MutedVideo className={videoIconClassName} />
        ) : (
          <Icons.Video className={videoIconClassName} />
        )}
      </div>

      <div
        onClick={handleOnMicrophoneClick}
        className={cn(buttonStyle, {
          "bg-emerald-300": !isAudioMuted,
          "bg-emerald-400": isAudioMuted,
          [disabledButtonStyle]: isAudioButtonDisabled,
        })}
      >
        {isAudioMuted ? (
          <Icons.MutedMicrophone className={cn(audioIconClassName, "pr-0.5")} />
        ) : (
          <Icons.Microphone className={cn(audioIconClassName, "pl-0.5")} />
        )}
      </div>

      <div
        onClick={handleOnCallEndClick}
        className={cn(buttonStyle, "bg-red-700")}
      >
        <Icons.Phone className="fill-slate-900" />
      </div>
    </div>
  )
}

type PendingCallProps = {
  view: View
}
const PendingCall = ({ view }: PendingCallProps): JSX.Element => {
  const { t } = useTranslation()

  const isVerticalView = view === "Vertical"
  const isHorizontalView = view === "Horizontal"

  const showRingingDetails = isLiveAgentAvailable && isVerticalView

  return (
    <div
      className={cn("flex flex-col items-center justify-center space-y-4", {
        "h-48": isVerticalView,
        "h-32": isHorizontalView,
      })}
    >
      <div className="text-2xl text-emerald-100">{t("call.ringing.title")}</div>

      {showRingingDetails && (
        <div className="text-xs text-emerald-100 max-w-[220px] text-center">
          {t("call.ringing.details")}
        </div>
      )}
    </div>
  )
}

type VideoContainerProps = {
  view: View
}
const VideoContainer = ({ view }: VideoContainerProps): JSX.Element => {
  const { state } = useCallContext()

  const showVideoContainer = state.kind === "ActiveCall"

  const isVerticalView = view === "Vertical"
  const isHorizontalView = view === "Horizontal"

  return (
    <div
      className={cn(
        "flex justify-center items-center duration-300 transition-all",
        {
          hidden: !showVideoContainer,
          "w-48 h-48": isVerticalView,
          "w-24 h-24 pr-4": isHorizontalView,
        },
      )}
    >
      <div
        className={cn(
          "rounded-xl overflow-hidden duration-300 transition-all",
          {
            "w-36 h-36": isVerticalView,
            "w-24 h-24": isHorizontalView,
          },
        )}
      >
        <div id="video" className="w-full h-full scale-150" />
      </div>
    </div>
  )
}

const UnavailableAgentPlaceholder = (): JSX.Element => {
  const { t } = useTranslation()

  return (
    <div className="text-base text-slate-900 text-center">
      {t("call.live_agent_unavailable")}
    </div>
  )
}

export default CallConnectionWrapper
