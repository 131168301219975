import { Chat } from "@app/model"
import * as S from "@effect/schema/Schema"
import { Either, pipe, ReadonlyArray } from "effect"

type APIMessageAlias = "AGENT" | "USER"
const APIMessageAliasSchema: S.Schema<APIMessageAlias> = S.union(
  S.literal("AGENT"),
  S.literal("USER"),
)

export type APIMessage = {
  id: number
  timestamp: number
  userId: string
  deviceId: string
  payload: string
  alias: APIMessageAlias
}

const APIMessageSchema: S.Schema<APIMessage> = S.struct({
  id: S.number,
  timestamp: S.number,
  alias: APIMessageAliasSchema,
  userId: S.string,
  deviceId: S.string,
  payload: S.string,
})

export const APIMessageListSchema: S.Schema<readonly APIMessage[]> =
  S.array(APIMessageSchema)

type ClientMessage = Chat.Message

const toModel = (apiMessage: APIMessage): ClientMessage => {
  const { id, timestamp, userId, payload, alias } = apiMessage
  return {
    id: id.toString(),
    timestamp,
    user: userId,
    text: payload,
    alias,
  }
}

export const decode = (apiResult: unknown[]): ClientMessage[] => {
  return pipe(
    apiResult,
    S.decodeUnknownEither(APIMessageListSchema),
    Either.map(ReadonlyArray.map(toModel)),
    Either.match({
      onLeft: parseError => {
        console.log("Response parse error", parseError.message)
        return []
      },
      onRight: messages => {
        console.log("parsed messages", messages)
        return messages
      },
    }),
  )
}
