import { useEffect } from "react"
import { jwtDecode } from "jwt-decode"

import * as APIError from "api/error"

import * as Utils from "../utils"

const isTokenExpired = (tokenExpiration: number): boolean => {
  const currentTime = Date.now() / 1000
  return tokenExpiration < currentTime
}

const checkTokenExpiration = (actualToken?: string): string => {
  const token = actualToken ?? Utils.getAccessToken()
  if (token) {
    const jwt = jwtDecode(token)
    if (jwt?.exp && isTokenExpired(jwt?.exp)) {
      throw APIError.Unauthorized
    }
  } else {
    throw APIError.UnknownError
  }
  return token
}

const useFetchInterceptor = (): void => {
  useEffect(() => {
    const originalFetch = window.fetch

    window.fetch = async (...args): Promise<Response> => {
      if (args[1]?.headers) {
        const headers = new Headers(args[1].headers)
        const newAccessToken = checkTokenExpiration(
          headers.get("access_token") as string,
        )
        args[1].headers = {
          ...args[1].headers,
          Authorization: `Bearer ${newAccessToken}`,
          access_token: newAccessToken,
        }
      }
      const response = await originalFetch(
        ...(args as [RequestInfo, RequestInit?]),
      )

      return response
    }

    return () => {
      window.fetch = originalFetch
    }
  }, [])
}

export default useFetchInterceptor
