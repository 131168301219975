import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import * as MobileAPI from "@app/api/mobile"
import * as HeroIcon from "@heroicons/react/24/solid"
import cn from "classnames"

const RefreshButton = (): JSX.Element => {
  const { t } = useTranslation()
  const [refreshing, setRefreshing] = useState(false)

  const handleOnRefreshClick = (): void => {
    try {
      MobileAPI.Send.sendMessage(MobileAPI.Send.REFRESH_POST_MESSAGE)
      setRefreshing(true)
    } catch {
      window.location.reload()
    }
  }

  return (
    <div
      onClick={handleOnRefreshClick}
      className="flex flex-row w-fit items-center justify-between px-4 py-2 space-x-2 text-slate-900 rounded-full bg-white hover:opacity-80 cursor-pointer"
    >
      <span>{t("common.refresh")} </span>

      <HeroIcon.ArrowPathIcon
        className={cn("w-4 h-4 text-slate-900", {
          "animate-spin": refreshing,
        })}
      />
    </div>
  )
}

export default RefreshButton
