import { Logger } from "@app/model"

import { REFRESH_POST_MESSAGE } from "./mobile/send"
import { fetchWithAuth } from "./api"
import * as APIError from "./error"

type PostChannelResponse = {
  channelId: string
  joinToken: string
}

type PostChannelParams = {
  deviceId: string
  clientId: string
  sendAlarm?: boolean
  shouldRestartOnError?: boolean
}
export const postChannel = async ({
  deviceId,
  clientId,
  sendAlarm = false,
  shouldRestartOnError = false,
}: PostChannelParams): Promise<PostChannelResponse> => {
  const body = {
    deviceId,
    clientId,
    sendAlarm,
  }

  const path = `live-agent/api/v2/channels/?channel-version=v2`

  const response = await fetchWithAuth({
    method: "POST",
    path,
    body,
  })

  if (!response.ok) {
    switch (response.status) {
      case 401:
        if (shouldRestartOnError) {
          Logger.log(REFRESH_POST_MESSAGE)
          break
        } else {
          throw APIError.Unauthorized
        }
      default:
        throw new Error(
          `Failed to POST liveSwitch channel for the the device id: ${deviceId}, clientId: ${clientId}`,
        )
    }
  }

  const result = (await response.json()) as PostChannelResponse

  return result
}
