import React, { ReactNode, useCallback, useEffect } from "react"
import { useTranslation } from "react-i18next"
import * as ErrorHandler from "@app/errorHandler"
import { Logger } from "@app/model"
import { State as CallState } from "@app/model/call"
import * as HeroIcon from "@heroicons/react/24/solid"
import { useCallContext } from "App/CallProvider"
import { TFunction } from "i18next"
import * as LiveSwitchApp from "liveswitch"

import * as API from "api"
import * as APIError from "api/error"
import * as UIKit from "uiKit"

import CallConnection from "./CallConnection"
import Chat from "./Chat"

const toErrorMessage = (t: TFunction, error: string): string => {
  switch (error) {
    case APIError.Unauthorized:
      return t("call.error.unauthorized")
    default:
      return error
  }
}

const LiveSwitchCall = (): JSX.Element => {
  const { t } = useTranslation()
  const { state } = useCallContext()

  switch (state.kind) {
    case "Idle":
      return <Idle />
    case "ConnectionEstablished":
    case "PendingCall":
    case "ActiveCall": {
      return <ActiveConnection state={state.kind} />
    }
    case "Error": {
      const errorMessage = toErrorMessage(t, state.error)
      return <Error message={errorMessage} />
    }
  }
}

type ActiveConnectionProps = {
  state: Extract<
    CallState["kind"],
    "ConnectionEstablished" | "PendingCall" | "ActiveCall"
  >
}
const ActiveConnection = ({ state }: ActiveConnectionProps): JSX.Element => {
  return (
    <div className="flex flex-col h-full">
      <div className="bg-slate-600 rounded-b-[30px]">
        <Call state={state} />
      </div>

      <Chat />
    </div>
  )
}

const LiveSwitchCallWrapper = (): JSX.Element => {
  return (
    <div className="grow h-full justify-center items-center transition-all duration-300">
      <LiveSwitchCall />
    </div>
  )
}

const Idle = (): JSX.Element => {
  const { changeState } = useCallContext()

  const init = useCallback(async (): Promise<void> => {
    try {
      await LiveSwitchApp.init()
      changeState({
        kind: "ConnectionEstablished",
      })
    } catch (error) {
      ErrorHandler.captureException(error)
      changeState({ kind: "Error", error: `${error}` })
    }
  }, [changeState])

  useEffect(() => {
    void init()
  }, [init])

  return <></>
}

type CallProps = {
  state: Extract<
    CallState["kind"],
    "ConnectionEstablished" | "PendingCall" | "ActiveCall"
  >
}
const Call = ({ state }: CallProps): JSX.Element => {
  switch (state) {
    case "ConnectionEstablished":
      return <CallIdle />
    case "PendingCall":
    case "ActiveCall":
      return <CallConnection />
  }
}

type ErrorProps = {
  message: string
}
const Error = ({ message }: ErrorProps): JSX.Element => {
  useEffect(() => {
    Logger.log("ERROR")
  })

  return (
    <div className="flex flex-col items-center space-y-4 pt-4">
      <UIKit.ErrorBanner message={message} />
      <UIKit.RefreshButton />
    </div>
  )
}

type IdleButtonProps = {
  title: string
  onClick: () => void
  children: ReactNode
}
const IdleButton = ({
  title,
  onClick,
  children,
}: IdleButtonProps): JSX.Element => {
  return (
    <div
      onClick={onClick}
      className="flex flex-row relative w-24 space-x-2 items-center justify-center py-1 px-6 rounded-full bg-slate-950"
    >
      <div className="w-4 h-4 p-2 rounded-full bg-slate-950 justify-self-end">
        {children}
      </div>
      <span className="text-base text-emerald-100">{title}</span>
    </div>
  )
}

const CallIdle = (): JSX.Element => {
  const { t } = useTranslation()
  const { changeState, ringtone } = useCallContext()

  const handleOnVideoCallClick = async (): Promise<void> => {
    try {
      await ringtone?.play()
      changeState({ kind: "PendingCall" })
      await LiveSwitchApp.runCall({ videoMuted: false })
      await API.postChannel({
        deviceId: LiveSwitchApp.client.getDeviceId(),
        clientId: LiveSwitchApp.client.getId(),
        sendAlarm: true,
      })
      Logger.log("SENDING_ALARM")
      changeState({
        kind: "ActiveCall",
        audioMuted: false,
        videoMuted: false,
      })
    } catch (error) {
      ringtone?.pause()
      ErrorHandler.captureException(error)
      changeState({ kind: "Error", error: `${error}` })
    }
  }

  const handleOnAudioCallClick = async (): Promise<void> => {
    try {
      await ringtone?.play()
      changeState({ kind: "PendingCall" })
      await LiveSwitchApp.runCall({ videoMuted: true })
      await API.postChannel({
        deviceId: LiveSwitchApp.client.getDeviceId(),
        clientId: LiveSwitchApp.client.getId(),
        sendAlarm: true,
      })
      Logger.log("SENDING_ALARM")
      changeState({
        kind: "ActiveCall",
        audioMuted: false,
        videoMuted: true,
      })
    } catch (error) {
      ringtone?.pause()
      ErrorHandler.captureException(error)
      changeState({ kind: "Error", error: `${error}` })
    }
  }

  return (
    <div className="flex items-center justify-center space-x-4 py-8">
      <IdleButton title={t("call.video")} onClick={handleOnVideoCallClick}>
        <HeroIcon.VideoCameraIcon className="text-emerald-100" />
      </IdleButton>

      <IdleButton title={t("call.call")} onClick={handleOnAudioCallClick}>
        <HeroIcon.PhoneIcon className="text-emerald-100" />
      </IdleButton>
    </div>
  )
}

export default LiveSwitchCallWrapper
