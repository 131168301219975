import * as S from "@effect/schema/Schema"
import { Option, pipe } from "effect"

import * as Date from "./date"
import * as Logger from "./logger"

type User = string

export type Alias = "AGENT" | "USER"
const AliasSchema: S.Schema<Alias> = S.union(
  S.literal("AGENT"),
  S.literal("USER"),
)

export type Message = {
  id: string
  text: string
  timestamp: Date.Posix
  user: User
  alias: Alias
}

export const toAlias = (maybeAlias: string): Alias => {
  return pipe(
    maybeAlias,
    S.decodeUnknownOption(AliasSchema),
    Option.getOrElse(() => {
      Logger.log(`Unexpected alias: ${maybeAlias}`)
      return maybeAlias as Alias
    }),
  )
}
