import * as S from "@effect/schema/Schema"
import { Option, pipe } from "effect"

type ReceiveMessage = string

const ReceiveMessageSchema: S.Schema<ReceiveMessage> = S.string

export const subscribe = (
  callback: (message: ReceiveMessage) => void,
): (() => void) => {
  const listener = (event: MessageEvent): void => {
    pipe(
      event.data,
      S.decodeUnknownOption(ReceiveMessageSchema),
      Option.map(callback),
    )
  }

  window.addEventListener("message", listener)

  return () => {
    window.removeEventListener("message", listener)
  }
}
