import { Chat } from "@app/model"

import { fetchWithAuth } from "./api"
import * as APIMessage from "./APIMessage"
import * as APIError from "./error"

type GetChatHistoryParams = {
  channelId: string
}
export const getChatHistory = async ({
  channelId,
}: GetChatHistoryParams): Promise<Chat.Message[]> => {
  const path = `live-agent/api/v2/messages/retrieve/${channelId}`

  const response = await fetchWithAuth({
    method: "GET",
    path,
  })

  if (!response.ok) {
    switch (response.status) {
      case 401:
        throw APIError.Unauthorized
      default:
        throw new Error(
          `Failed to GET liveSwitch chat history for the channel id: ${channelId}`,
        )
    }
  }

  const result = (await response.json()) as unknown[]

  return APIMessage.decode(result)
}
