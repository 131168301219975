import React, { useEffect } from "react"
import { subscribe } from "@app/api/mobile/receive"
import useFetchInterceptor from "@app/api/useFetchInterceptor"
import { setAccessToken } from "@app/utils"
import * as Sentry from "@sentry/react"

import { CallProvider } from "./CallProvider"
import ErrorBoundaryFallback from "./ErrorBoundaryFallback"
import LiveSwitchCall from "./LiveSwitchCall"

const App = (): JSX.Element => {
  useFetchInterceptor()

  useEffect(() => {
    subscribe(setAccessToken)
  }, [])

  return (
    <Sentry.ErrorBoundary fallback={<ErrorBoundaryFallback />}>
      <CallProvider>
        <AppView />
      </CallProvider>
    </Sentry.ErrorBoundary>
  )
}

const AppView = (): JSX.Element => {
  return (
    <div className="grow h-full">
      <LiveSwitchCall />
    </div>
  )
}

export default App
