type ErrorBannerProps = {
  message: string
}
const ErrorBanner = ({ message }: ErrorBannerProps): JSX.Element => {
  return (
    <div className="p-4 bg-slate-900 rounded-lg">
      <div className="text-base text-red-400 break-all">{message}</div>
    </div>
  )
}

export default ErrorBanner
