import { Chat, Logger } from "@app/model"
import liveswitch from "fm.liveswitch"
import { v4 as uuidv4 } from "uuid"

import { ringtone } from "./App/CallProvider"
import * as API from "./api"
import * as Utils from "./utils"

let localMedia: liveswitch.LocalMedia | undefined
let layoutManager: liveswitch.DomLayoutManager
export let channel: liveswitch.Channel | undefined
let connection: liveswitch.McuConnection | undefined
export let client: liveswitch.Client

const url = process.env.GATEWAY_URL ?? ""
const appID = process.env.APPLICATION_ID ?? ""

const userId = Utils.getUserId()
const userAlias: Chat.Alias = "USER"

const peerLeft = (name: string): void => {
  Logger.log(name + " left.")
}

const peerJoined = (name: string): void => {
  Logger.log(name + " joined.")
}

export const init = async (): Promise<void> => {
  try {
    client = new liveswitch.Client(url, appID, userId)
    client.setUserAlias(userAlias)

    const clientId = client.getId()
    const deviceId = client.getDeviceId()

    Logger.log("clientId: " + clientId)
    Logger.log("deviceId: " + deviceId)

    const { channelId, joinToken } = await API.postChannel({
      deviceId,
      clientId,
      shouldRestartOnError: true,
    })

    Logger.log("channelId: " + channelId)
    Logger.log("joinToken: " + joinToken)

    if (!channelId || !joinToken) {
      Logger.log("REFRESH")
    } else {
      await client.register(joinToken).then(async channels => {
        channel = channels[0]
        Logger.log("connected to channel: " + channel?.getId())

        await client
          .join(channelId, joinToken)
          .then(_channel => {
            Logger.log("successfully joined channel")
          })
          .fail(function (_ex) {
            console.log(_ex)
            Logger.log("failed to join channel")
            return false
          })
      })
    }
  } catch (e) {
    if (JSON.stringify(e).includes("Unknown")) {
      Logger.log("REFRESH")
    }
    throw e
  }
}

type HandleLocalMediaParams = {
  videoMuted?: boolean
}
const handleLocalMedia = async ({
  videoMuted,
}: HandleLocalMediaParams): Promise<void> => {
  const audio = true
  const videoWidth = 320
  const videoHeight = 320
  const video = new liveswitch.VideoConfig(videoWidth, videoHeight, 10)
  localMedia = new liveswitch.LocalMedia(audio, video)

  if (videoMuted) {
    muteVideo()
  }

  await localMedia
    .start()
    .then(async _lm => {
      Logger.log("media capture started")
      const element = document.getElementById("video")
      if (element && localMedia) {
        layoutManager = new liveswitch.DomLayoutManager(element)
        layoutManager.setLocalView(localMedia.getView())

        await openMcuConnection()
      }
    })
    .fail(function (ex) {
      Logger.log(ex.message)
    })
}

type RunCallParams = {
  videoMuted?: boolean
}
export const runCall = async ({ videoMuted }: RunCallParams): Promise<void> => {
  try {
    await handleLocalMedia({ videoMuted })
  } catch (e) {
    Logger.log("failed to run a call")
    throw e
  }
}

const openMcuConnection = async (): Promise<void> => {
  if (!localMedia) {
    return
  }
  const remoteMedia = new liveswitch.RemoteMedia()
  const audioStream = new liveswitch.AudioStream(localMedia, remoteMedia)
  const videoStream = new liveswitch.VideoStream(localMedia, remoteMedia)
  connection = channel?.createMcuConnection(audioStream, videoStream)

  const upstreamConnectionInfos =
    channel?.getRemoteUpstreamConnectionInfos() ?? []

  if (upstreamConnectionInfos.length > 0) {
    ringtone.pause()
  }

  // Add the remote video view to the layout.
  if (remoteMedia.getView()) {
    remoteMedia.getView().id = "remoteView_" + remoteMedia.getId()
  }
  layoutManager.addRemoteView(remoteMedia.getId(), remoteMedia.getView())

  connection?.addOnStateChange(function (c) {
    if (
      c.getState() === liveswitch.ConnectionState.Closing ||
      c.getState() === liveswitch.ConnectionState.Failing
    ) {
      layoutManager.removeRemoteView(remoteMedia.getId())
    }
  })

  onClientRegistered(peerLeft, peerJoined)

  let videoLayout: liveswitch.VideoLayout
  channel?.addOnMcuVideoLayout(function (vl) {
    videoLayout = vl
    if (layoutManager != null) {
      layoutManager.layout()
    }
  })

  layoutManager.addOnLayout(function (layout) {
    if (connection != null) {
      liveswitch.LayoutUtility.floatLocalPreview(
        layout,
        videoLayout,
        connection.getId(),
      )
    }
  })

  if (!connection) {
    return
  }
  await connection
    .open()
    .then(function (_result) {
      Logger.log("mixed connection established")
    })
    .fail(function (_ex) {
      Logger.log("an error occurred while openMcuConnection")
    })
}

function onClientRegistered(
  peerLeftFn: (name: string) => void,
  peerJoinedFn: (name: string) => void,
): void {
  try {
    channel?.addOnRemoteClientJoin(remoteClientInfo => {
      liveswitch.Log.info(
        `Remote client joined the channel (client ID: ${remoteClientInfo.getId()}, device ID: ${remoteClientInfo.getDeviceId()}, user ID: ${remoteClientInfo.getUserId()}, tag: ${remoteClientInfo.getTag()}).`,
      )

      const n =
        remoteClientInfo.getUserAlias() != null
          ? remoteClientInfo.getUserAlias()
          : remoteClientInfo.getUserId()
      peerJoinedFn(n)
    })
  } catch (e) {
    Logger.log("Failed to register the client")
  }
  channel?.addOnRemoteClientLeave(remoteClientInfo => {
    const n =
      remoteClientInfo.getUserAlias() != null
        ? remoteClientInfo.getUserAlias()
        : remoteClientInfo.getUserId()
    peerLeftFn(n)

    liveswitch.Log.info(
      `Remote client left the channel (client ID: ${remoteClientInfo.getId()}, device ID: ${remoteClientInfo.getDeviceId()}, user ID: ${remoteClientInfo.getUserId()}, tag: ${remoteClientInfo.getTag()}).`,
    )
  })

  // Monitor the channel remote upstream connection changes.
  channel?.addOnRemoteUpstreamConnectionOpen(remoteConnectionInfo => {
    if (remoteConnectionInfo.getUserAlias() === "AGENT") {
      ringtone.pause()
    }
    liveswitch.Log.info(
      `Remote client opened upstream connection (connection ID: ${remoteConnectionInfo.getId()}, client ID: ${remoteConnectionInfo.getClientId()}, device ID: ${remoteConnectionInfo.getDeviceId()}, user ID: ${remoteConnectionInfo.getUserId()}, tag: ${remoteConnectionInfo.getTag()}).`,
    )
  })
  channel?.addOnRemoteUpstreamConnectionClose(remoteConnectionInfo => {
    liveswitch.Log.info(
      `Remote client closed upstream connection (connection ID: ${remoteConnectionInfo.getId()}, client ID: ${remoteConnectionInfo.getClientId()}, device ID: ${remoteConnectionInfo.getDeviceId()}, user ID: ${remoteConnectionInfo.getUserId()}, tag: ${remoteConnectionInfo.getTag()}).`,
    )
  })
}

export const stopCall = (): void => {
  localMedia?.stop().then(
    async _o => {
      if (!connection) {
        return
      }

      await connection
        .close()
        .then(function (_result) {
          layoutManager.unsetLocalView()
          localMedia?.destroy()

          Logger.log("connection closed")
        })
        .fail(function (_ex: unknown) {
          Logger.log("an error occurred while closing connection")
        })
    },
    () => {
      Logger.log("Failed to stop call")
    },
  )
}

export const unregisterClient = async (): Promise<void> => {
  await client
    .unregister()
    .then(function (_result: unknown) {
      Logger.log("unregistration succeeded")
    })
    .fail(function (_ex: unknown) {
      Logger.log("unregistration failed")
    })
}

export const isMutedAudio = (): boolean => {
  if (!localMedia) {
    return false
  }
  return localMedia.getAudioMuted()
}

export const isMutedVideo = (): boolean => {
  if (!localMedia) {
    return false
  }
  return localMedia.getVideoMuted()
}

export const toggleMuteAudio = (): void => {
  if (!localMedia) {
    return
  }
  const nextMuted = !isMutedAudio()
  localMedia.setAudioMuted(nextMuted)
}

export const toggleMuteVideo = (): void => {
  if (!localMedia) {
    return
  }
  const nextMuted = !isMutedVideo()
  localMedia.setVideoMuted(nextMuted)
}

export const muteVideo = (): void => {
  localMedia?.setVideoMuted(true)
}

export const isMessageFromMe = (message: Chat.Message): boolean => {
  return message.alias === userAlias
}

export const addIncomingChatHandler = (
  callback: (callbackMessage: Chat.Message) => void,
): (() => void) => {
  const handler = (sender: liveswitch.ClientInfo, text: string): void => {
    const user = sender.getUserId()
    const alias = Chat.toAlias(sender.getUserAlias())

    const message: Chat.Message = {
      id: uuidv4(),
      text,
      timestamp: Date.now(),
      user,
      alias,
    }
    Logger.log(
      `receive message: ${text}, from ${sender.getUserAlias()} (${user})`,
    )

    callback(message)
  }

  channel?.addOnMessage(handler)

  return (): void => {
    channel?.removeOnMessage(handler)
  }
}

export const getChatHistory = async (): Promise<Chat.Message[]> => {
  if (!channel) {
    Logger.log("channel is not initialized to fetch chat history")
    return []
  }
  const channelId = channel.getId()
  return API.getChatHistory({ channelId })
}
