import { initReactI18next } from "react-i18next"
import i18n, { use } from "i18next"

import enUS from "./locales/en-US/translation.json"
import esMX from "./locales/es-MX/translation.json"

export type Locale = "en-US" | "es-MX"
export const supportedLocales: Locale[] = ["en-US", "es-MX"]

export const resources = {
  "en-US": {
    translation: enUS,
  },
  "es-MX": {
    translation: esMX,
  },
} as const

const defaultLocale = "en-US"
const defaultNS = "translation"

void use(initReactI18next).init({
  resources,
  supportedLngs: supportedLocales,
  fallbackLng: defaultLocale,
  ns: [defaultNS],
  defaultNS,
  returnNull: false,
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
})

export default i18n
