export type Posix = number

export const format = (posix: Posix): string => {
  // "Jan 1, 4:00 AM"
  return new Date(posix).toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    month: "short",
    day: "numeric",
  })
}
