import React, { createContext, ReactNode, useContext, useState } from "react"
import { Call } from "@app/model"

export const ringtone = new Audio(`${window.location.origin}/ringtone.mp3`)
ringtone.loop = true

export type CallContextState = {
  state: Call.State
  changeState: (nextState: Call.State) => void
  ringtone?: HTMLAudioElement
}
const initialCallContextState: CallContextState = {
  state: {
    kind: "Idle",
  },
  changeState: () => {},
  ringtone: undefined,
}
export const CallContext = createContext<CallContextState>(
  initialCallContextState,
)

type CallProviderProps = {
  children: ReactNode
}

export const CallProvider = ({ children }: CallProviderProps): JSX.Element => {
  const [callState, setCallState] = useState<Call.State>({ kind: "Idle" })

  const onCallStateChanged = (nextState: Call.State): void => {
    setCallState(nextState)
  }

  return (
    <CallContext.Provider
      value={{ state: callState, changeState: onCallStateChanged, ringtone }}
    >
      {children}
    </CallContext.Provider>
  )
}

export const useCallContext = (): CallContextState => {
  const context = useContext(CallContext)

  if (context === undefined) {
    throw new Error("CallContext must be used with a Provider")
  }

  return context
}
