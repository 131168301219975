{
  "call": {
    "call": "Call",
    "error": {
      "unauthorized": "Session has expired, try to Refresh."
    },
    "live_agent_unavailable": "Live agent is currently unavailable.",
    "ringing": {
      "details": "Hang in there, we are connecting you with our agent!",
      "title": "Calling..."
    },
    "video": "Video"
  },
  "common": {
    "refresh": "Refresh"
  },
  "emergency_disclaimer": "Emergency responders will be contacted upon your request, consent or at the live agent's discretion given the circumstances.",
  "error_boundary": {
    "message": "Something went wrong. Please refresh."
  }
}
