const USER_ID_PARAM = "userId"
const ACCESS_TOKEN_PARAM = "token"
const REST_URL_PARAM = "restURL"

// Get URL Params
//
// example:
// get https://liveswitch.dev.prevent.evolvdevelopment.com?restURL=https://dev.prevent.evolvdevelopment.com&token=[access_token]&userId=[user_id]

const getUrlParams = (): URLSearchParams => {
  return new URLSearchParams(window.location.search)
}

export const getUserId = (): string => {
  const params = getUrlParams()
  return params.get(USER_ID_PARAM) ?? ""
}

export const getAccessToken = (): string => {
  return localStorage.getItem(ACCESS_TOKEN_PARAM) ?? ""
}

export const setAccessToken = (token: string): void => {
  localStorage.setItem(ACCESS_TOKEN_PARAM, token)
}

export const getRestURL = (): string => {
  const params = getUrlParams()
  return params.get(REST_URL_PARAM) ?? ""
}
